import useSiteAssets from '@/services/hooks/useSiteAssets'

const TimeRemaining = ({ campaign, className }) => {
  const { timeRemainingValue, timeRemainingDimension } = campaign.timeRemaining
  const clarificationSymbol = campaign.isSellingFast === true ? '†' : '*'
  const siteAssets = useSiteAssets()

  return (
    <div className={className} data-testid="TimeRemaining">
      <div>
        <div className="flex flex-col items-end">
          <div className="text-xl lg:text-2xl leading-1">
            {timeRemainingValue}
          </div>
          <div className="text-sm whitespace-nowrap">
            {timeRemainingDimension} {siteAssets?.timeRemainingDescriptor}
            {clarificationSymbol}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeRemaining
