import React from 'react'
import Team from './Team'
import About from './About'
import Quote from './Quote'
import Highlights from './Highlights'
import { VideoBox } from '@/components/common'

interface Props {
  about: any[][]
  campaignSlug: string
}

export const OverviewOld = ({ about, campaignSlug }: Props) => {
  const items = about?.map((obj, i) => (
    <div
      key={i}
      style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray' }}
    >
      {obj?.map((item, ind) => (
        <div key={ind} className={ind < obj.length - 1 ? 'mb-4' : ''}>
          {item.type === 'team' && <Team {...item} />}
          {item.type === 'about' && <About {...item} />}
          {item.type === 'quote' && <Quote {...item} />}
          {item.type === 'highlights' && <Highlights {...item} />}
          {item.type === 'video' && (
            <VideoBox
              id={`overview-${item.videoId}`}
              campaignSlug={campaignSlug}
              video={item.videoId}
            />
          )}
        </div>
      ))}
    </div>
  ))
  return <div className="h-full">{items}</div>
}

export default OverviewOld
