import { Campaign } from '@/services/api/campaignV2/campaign'
import React from 'react'
import { Text } from '@/components/common'
import { formatMoney } from '@/utils'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'

interface Props {
  campaign: Campaign
  className?: string
}

const InvestmentDetails: React.FC<Props> = ({ campaign, className }) => {
  const perShare = formatMoney(campaign.sharePrice)
  const assets = useCampaignAssets()

  if (!assets.valuation) return null

  return (
    <div className={className}>
      <div className="flex justify-between items-center">
        <Text
          as="div"
          preset="heading.eyebrow"
          className="uppercase leading-none flex-1"
        >
          Investment Details:
        </Text>

        <div className="ml-8 flex-grow-0">
          <Text as="div" preset="body.md">
            {perShare}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Per Share
          </Text>
        </div>

        <div className="ml-8 flex-[0_3.75rem]">
          <Text as="div" preset="body.md">
            {assets.valuation}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Pre-Money Valuation
          </Text>
        </div>
      </div>
    </div>
  )
}

export default InvestmentDetails
