import { ChangeEvent, useState } from 'react'
import { VideoBox, Select } from '@/components/common'
import CommentSection from '../CommentSection'

interface Props {
  campaignSlug: string
  livestreams: {
    id: string
    name: string
  }[]
}

const Livestream = ({ livestreams, campaignSlug }: Props) => {
  const [stream, setStream] = useState(livestreams[0].id)

  return (
    <>
      <Select
        id="livestream-selector"
        name="livestream-selector"
        options={livestreams?.map((stream) => ({
          label: stream.name,
          value: stream.id,
        }))}
        value={stream || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          setStream(e.target.value)
        }
        className="w-60"
      />

      <VideoBox
        id={`livestream-${stream}`}
        campaignSlug={campaignSlug}
        video={stream}
        className="mt-5"
      />

      <CommentSection video={stream} />
    </>
  )
}

export default Livestream
