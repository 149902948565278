import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FilledButton, HollowButton, FormInput } from '@/components/common'
import Loader from 'react-loader-spinner'
import tailwind from '../../../../../tailwind.config'
import useAuth from '@/services/hooks/useAuth'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { sleep } from '@/utils'

const InvestSubscribeForm = ({
  campaignSlug,
  subscribedEmail,
  setSubscribedEmail,
  closeModal,
}) => {
  const { user } = useAuth()
  const isSubscribed = !!subscribedEmail

  const onSubmit = useCallback(
    async (values) => {
      SegmentHandler.track('User Subscribed', {
        project: campaignSlug,
      })

      if (!user) {
        SegmentHandler.identify({ email: values.email })
      }

      closeModal()

      // Setting subscribedEmail after a delay to allow the modal to close
      await sleep(200)
      setSubscribedEmail(isSubscribed ? null : values.email)
    },
    [campaignSlug, isSubscribed, setSubscribedEmail, closeModal, user]
  )

  return (
    <Formik
      initialValues={{
        email: subscribedEmail || user?.email || '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email'),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="w-full">
          {isSubscribed && (
            <p className="mt-0 mb-4">
              When you unsubscribe you will no longer receive any emails or
              updates on the progress of the show.
            </p>
          )}

          <div className="w-full pt-2">
            <FormInput name="email" displayName="Email" />
          </div>

          <div className="grid grid-cols-2 gap-1 mt-6">
            <HollowButton
              color="orange"
              disabled={isSubmitting}
              onClick={closeModal}
              className="mr-4"
              type="button"
            >
              Cancel
            </HollowButton>

            <FilledButton
              color="orange"
              disabled={isSubmitting}
              type="submit"
              className="flex-1 flex items-center justify-center"
            >
              {isSubmitting && (
                <Loader
                  type="TailSpin"
                  color={tailwind.theme.colors.lightGray}
                  className="mr-2"
                  height={25}
                  width={25}
                />
              )}
              <span>{subscribedEmail ? 'Unsubscribe' : 'Subscribe'}</span>
            </FilledButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

InvestSubscribeForm.propTypes = {
  campaignSlug: PropTypes.string.isRequired,
  subscribedEmail: PropTypes.string,
  setSubscribedEmail: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default InvestSubscribeForm
