import { Fire } from '@/components/svg'

const SellingFast = ({ campaign, className }) => {
  const { timeRemainingValue, timeRemainingDimension } = campaign.timeRemaining
  const areOrIs = timeRemainingValue === 1 ? 'is' : 'are'

  if (!campaign.isSellingFast) {
    return null
  }

  return (
    <div className={className}>
      <div className="capitalize font-semibold flex-row flex items-center">
        <Fire color="#D50000" size={20} />
        <span className="text-lg ml-1">SELLING FAST</span>
      </div>
      <div className="text-xs">
        †We estimate there {areOrIs} only {timeRemainingValue}{' '}
        {timeRemainingDimension} remaining based on the current volume of
        investments.
      </div>
    </div>
  )
}

export default SellingFast
