import useApi, { ApiRes } from '@/services/hooks/useApiV2'
import { getCampaign, Campaign } from '@/services/api/campaignV2/campaign'

const useCampaign = (slug: string) => {
  const {
    data: campaign,
    isLoading,
    isError,
  }: ApiRes<Campaign> = useApi({
    key: `campaign/${slug}`,
    request: () => getCampaign(slug),
  })

  return {
    campaign,
    isLoading,
    isError,
  }
}

export default useCampaign
