import { People } from '@/components/svg'

const Backers = ({ campaign, className = '' }) => {
  return (
    <div className={className}>
      <div className="flex-row flex items-center">
        <People />
        <div
          className="leading-5 ml-1 text-sm md:text-md"
          data-cy="backers_number"
        >
          {campaign.backers.toLocaleString()} People*
        </div>
      </div>
    </div>
  )
}

export default Backers
