import NextImage from 'next/image'
import Markdown from '@/components/Markdown'

export default function TeamMember({ image_url, name, text }) {
  return (
    <div className="flex flex-col md:flex-row my-10 md:mb-5">
      {image_url && (
        <div
          className="self-center md:self-start mb-4 mr-5 w-[130px] h-[130px] rounded-full overflow-hidden"
          style={{
            boxShadow: '0px 7px 8px #C0C0C0',
          }}
        >
          <NextImage
            alt={name}
            src={image_url}
            width={130}
            height={130}
            loading="lazy"
            objectFit="cover"
          />
        </div>
      )}

      <div className="flex flex-col text-center md:ml-3 md:block md:text-left md:w-[78%]">
        <p className="text-xl text-gray mb-2">{name}</p>
        <Markdown source={text} />
      </div>
    </div>
  )
}
