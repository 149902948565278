import { Document } from '@contentful/rich-text-types'
import { Asset } from 'contentful'
import { Text, TextBlock } from '@/components/common'
import Image from '@/components/contentful/Image'
import { contentUtil } from '@/utils'
import { IContentBlock } from 'src/types/generated/contentful'

interface Props {
  items: IContentBlock[]
}

interface Bio {
  id: string
  contentType: 'contentBlock'
  image?: Asset
  subtitle?: string
  text?: Document
  title: string
}

const Bios = ({ items = [] }: Props) => {
  const bios = items.map(contentUtil.mapContent) as unknown as Bio[]

  return (
    <div>
      {bios.map(({ id, image, subtitle, text, title: name }) => (
        <div key={id} className="flex flex-col md:flex-row my-10 md:mb-5">
          {image && (
            <div
              style={{ width: 130, height: 130 }}
              className="self-center md:self-start mb-4 mr-5"
            >
              <Image
                image={image}
                width={130}
                height={130}
                fit="fill"
                fill="face"
                className="overflow-hidden rounded-full h-[130px] w-[130px]"
                style={{
                  boxShadow: '0px 7px 8px #C0C0C0',
                }}
                lazy
              />
            </div>
          )}

          <div className="flex flex-col md:ml-3 md:w-[78%]">
            <Text
              as="p"
              preset="heading.lg"
              className="text-gray font-normal mb-2 text-center md:text-left"
            >
              {name}
            </Text>
            {subtitle && (
              <Text
                as="p"
                preset="body.lg"
                className="mb-2 text-center md:text-left italic"
              >
                {subtitle}
              </Text>
            )}
            {text && <TextBlock document={text} />}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Bios
