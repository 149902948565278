import React from 'react'
import classNames from 'classnames'
import { progressUtil, formatMoney } from '@/utils'

const ProgressBar = ({ campaign, trackColor = 'black', className = '' }) => {
  const { title = '', amount = 0 } = campaign.stretchGoal || {}
  const nextGoalString = `${title} ${formatMoney(amount, {
    isCents: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`

  return (
    <div className={className}>
      {title && amount && (
        <div className="text-gray w-full text-right text-xs">
          {nextGoalString}
        </div>
      )}
      <div
        className={classNames({
          'w-full bg-opacity-30 h-2 relative': true,
          'bg-white': trackColor === 'white',
          'bg-black': trackColor === 'black',
        })}
      >
        <div
          role="progressbar"
          className="h-full w-full bg-primary bg-opacity-80 h-2 relative"
          style={{
            width: progressUtil.getProgressWidth(campaign.progress),
          }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
