import { RefObject, useEffect, useState } from 'react'

function useOnScreen(ref: RefObject<Element>) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer =
    typeof window !== 'undefined' &&
    new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    if (!ref.current || !observer) return

    observer.observe(ref.current)

    // remove observer as soon as component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

export default useOnScreen
