import React from 'react'
import { Text } from '@/components/common'
import { Campaign } from '@/services/api/campaignV2/campaign'
import { ISectionAnticipatedRegulationTypeFields } from '../../../../../types/generated/contentful'
import useSiteAssets from '@/services/hooks/useSiteAssets'

export const cfDisclaimer =
  '*People includes all persons who have submitted subscription materials. Amount raised is an estimate that includes subscriptions, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.'
export const previousRoundDisclaimer =
  '*People includes all persons who have submitted subscription materials for the current and prior CF rounds. Amount raised is an estimate that includes subscriptions for the current and prior CF rounds, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.'
export const ttwCFDisclaimer = `*No money or other consideration is being solicited, and if sent in response, will not be accepted. No offer to buy the securities can be accepted and no part of the purchase price can be received until the offering statement is filed and only through an intermediary's platform. A person's indication of interest involves no obligation or commitment of any kind.`
export const ttwADisclaimer = `*No money or other consideration is being solicited or accepted at this time. No sales will be made or commitments to purchase accepted until the offering statement is qualified. A prospective purchaser's indication of interest in non-binding. Sales made pursuant to Regulation A are contingent upon the qualification of the offering statement.`

export interface Props {
  campaign: Campaign
  anticipatedRegulation?: ISectionAnticipatedRegulationTypeFields
  className?: string
}

const CampaignDisclaimer: React.FC<Props> = ({
  campaign,
  anticipatedRegulation,
  className,
}) => {
  const { regulationType, previousRound } = campaign
  const siteAssets = useSiteAssets()

  if (regulationType === 'testing_the_waters') {
    return (
      <div className={className}>
        <Text as="span" preset="custom">
          {anticipatedRegulation?.anticipatedRegulationType === 'Reg A'
            ? ttwADisclaimer
            : ttwCFDisclaimer}
        </Text>
        {anticipatedRegulation?.filedCircularLink?.fields?.url && (
          <Text as="span" preset="custom">
            {' '}
            The most recent preliminary offering circular can be viewed{' '}
            <a
              href={anticipatedRegulation?.filedCircularLink?.fields?.url}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              here
            </a>
            .
          </Text>
        )}
      </div>
    )
  }

  return (
    <div className={className}>
      <Text as="span" preset="custom">
        {siteAssets?.fundingCampaignDisclaimer
          ? siteAssets?.fundingCampaignDisclaimer
          : previousRound
          ? previousRoundDisclaimer
          : cfDisclaimer}
      </Text>
    </div>
  )
}

export default CampaignDisclaimer
