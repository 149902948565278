import NextImage from 'next/image'

export default function Quote(quote) {
  return (
    <>
      <div className="items-center m-3 md:my-6 hidden md:flex">
        <div
          className="self-start"
          style={
            quote.image_position === 'left'
              ? { paddingRight: '2em', width: 200, flex: 2 }
              : { width: 200, order: 2, paddingLeft: '2em', flex: 2 }
          }
        >
          <NextImage
            src={quote.image_url}
            className="object-contain w-full rounded-md"
            width={262}
            height={262}
            objectFit="cover"
            loading="lazy"
            alt={`Image of ${quote.author}`}
          />
        </div>
        <div style={{ flex: 6 }}>
          <div className="italic mb-2 text-lg">&quot;{quote.text}&quot;</div>
          <div className="uppercase w-full tracking-widest text-gray text-md">
            {quote.author}
          </div>
          {quote.author_tag && (
            <div className="uppercase w-full tracking-widest text-gray text-xs">
              {quote.author_tag}
            </div>
          )}
        </div>
      </div>
      <div className="items-center m-3 my-5 flex flex-col md:hidden text-center">
        <div
          className="mb-4 rounded-full overflow-hidden w-[160px] h-[160px]"
          style={{
            boxShadow: '0px 7px 10px #C0C0C0',
          }}
        >
          <NextImage
            src={quote.image_url}
            width={160}
            height={160}
            objectFit="cover"
            loading="lazy"
          />
        </div>
        <div style={{ flex: 6 }}>
          <div className="italic mb-2 text-lg">&quot;{quote.text}&quot;</div>
          <div className="uppercase w-full tracking-widest text-gray text-sm">
            {quote.author}
          </div>
          {quote.author_tag && (
            <div className="uppercase w-full tracking-widest text-gray text-sm">
              {quote.author_tag}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
