import Image from '@/components/contentful/Image'
import { contentUtil, richText } from '@/utils'
import { Document } from '@contentful/rich-text-types'
import { Asset } from 'contentful'
import { Fragment } from 'react'
import { ISectionContentBlock } from 'src/types/generated/contentful'

interface Props {
  items?: ISectionContentBlock[]
}

interface Testimonial {
  id: string
  contentType: 'contentBlock'
  image?: Asset
  subtitle?: string
  text?: Document
  title: string
}

const Testimonials = ({ items = [] }: Props) => {
  const testimonials = items.map(
    contentUtil.mapContent
  ) as unknown as Testimonial[]

  return (
    <div>
      {testimonials.map(({ id, image, subtitle, text, title }, i) => (
        <Fragment key={id}>
          <div className="items-center m-3 md:my-6 hidden md:flex">
            {image && (
              <Image
                image={image}
                className="self-start object-contain w-full rounded-md"
                width={262}
                height={262}
                fit="fill"
                fill="face"
                lazy
                style={
                  i % 2 === 0
                    ? { paddingRight: '2em', width: 200, flex: 2 }
                    : { width: 200, order: 2, paddingLeft: '2em', flex: 2 }
                }
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="italic mb-2 text-lg">{richText(text)}</div>
              )}
              <div className="uppercase w-full tracking-widest text-gray text-md">
                {title}
              </div>
              {subtitle && (
                <div className="uppercase w-full tracking-widest text-gray text-xs">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
          <div className="items-center m-3 my-5 flex flex-col md:hidden text-center">
            {image && (
              <Image
                image={image}
                width={160}
                height={160}
                style={{
                  boxShadow: '0px 7px 10px #C0C0C0',
                }}
                className="rounded-full w-[160px] h-[160px] mb-4"
                lazy
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="italic mb-2 text-lg">{richText(text)}</div>
              )}
              <div className="uppercase w-full tracking-widest text-gray text-sm">
                {title}
              </div>
              {subtitle && (
                <div className="uppercase w-full tracking-widest text-gray text-sm">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default Testimonials
