import React, { useCallback } from 'react'
import {
  Comment,
  CommentReq,
} from '@/services/api/publicComments/publicComments'
import { Text } from '@/components/common'
import CampaignCommentForm from './CampaignCommentForm'
import CampaignCommentBody from './CampaignCommentBody'

export interface Props extends Comment {
  campaignName: string
  postResponse?: (req: CommentReq) => Promise<Comment>
  className?: string
}

const CampaignComment: React.FC<Props> = ({
  id,
  name,
  text,
  created,
  answer,
  campaign,
  approved,
  financialTies,
  campaignName,
  postResponse,
  className,
}) => {
  const handleSubmit = useCallback(
    (userId: string, text: string) =>
      postResponse?.({ userId, text, parentId: id, campaign }),
    [id, campaign, postResponse]
  )

  return (
    <div className={className}>
      <div className="flex flex-col">
        <CampaignCommentBody
          name={name}
          created={created}
          text={text}
          approved={!!approved}
        />

        {financialTies && (
          <Text as="p" preset="body.sm" className="text-gray mt-2">
            Disclaimer: This user has financial ties to{' '}
            <span className="italic">{campaignName}</span>
          </Text>
        )}

        {answer && (
          <CampaignCommentBody
            name={answer.name}
            created={answer.created}
            text={answer.text}
            approved={!!answer.approved}
            className="bg-gray-10 rounded p-4 mt-3 lg:mt-4"
          />
        )}

        {!answer && postResponse && (
          <CampaignCommentForm
            campaignSlug={campaign}
            // Even though type-guarded, typescript still complains since
            // handleSubmit is wrapped in useCallback
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={handleSubmit}
            className="mt-3 lg:mt-4"
            isResponse
          />
        )}
      </div>
    </div>
  )
}

export default CampaignComment
