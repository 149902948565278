import TeamMember from './TeamMember'
export default function Team({ members, team }) {
  return (
    <div className="m-3 md:m-0">
      {members?.map((data, index) => (
        <TeamMember {...data} key={index} />
      ))}
    </div>
  )
}
