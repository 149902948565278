import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { GetStaticPaths, GetStaticProps } from 'next'
import { getCampaignDetails } from '@/services/api/endpoints'
import {
  getCampaign,
  getAllCampaigns,
  Campaign,
} from '@/services/api/campaignV2/campaign'
import { getContent } from '@/services/contentful'
import {
  ICampaignAssets,
  IPageCampaign,
  ISiteAssetsFields,
} from 'src/types/generated/contentful'
import { CampaignShareBox, VideoModal } from '@/components/common'
import CampaignHeading from '@/components/pages/CampaignPages/CampaignHeading'
import Details from '@/components/pages/CampaignPages/Details'
import DetailsTabs from '@/components/pages/CampaignPages/DetailsTabs'
import { DetailsProvider } from '@/components/pages/CampaignPages/DetailsContext'
import InvestCard from '@/components/pages/CampaignPages/InvestCard'
import PublicComments from '@/components/pages/CampaignPages/PublicComments'
import VideoHero from '@/components/pages/CampaignPages/VideoHero'
import MobileInvestButtonWrapper from '@/components/pages/CampaignPages/InvestComponents/MobileInvestButtonWrapper'
import { contentUtil } from '@/utils'
import fbPixel from '@/services/analytics/fbPixel'
import useOnScreen from '@/services/hooks/useOnScreen'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { getSiteAssetsID } from '../constants/contentful'
import { resolveSiteAssets, SiteAssets } from '@/services/api/siteAssets'
import { CampaignAssetsProvider } from '@/components/context/CampaignAssetsContext'
import CampaignTags from '@/components/pages/CampaignPages/CampaignTags'
import CampaignDescription from '@/components/pages/CampaignPages/CampaignDescription'

export interface Props {
  siteAssets: SiteAssets
  campaign: Campaign
  campaignAssets: ICampaignAssets
  campaignDetails?: { header: { youtube_id: string } }
  content?: IPageCampaign
  videoAspectRatio?: number
}

export const CampaignPage: React.FC<Props> = ({
  campaign,
  campaignAssets,
  campaignDetails,
  content,
  siteAssets,
  videoAspectRatio,
}) => {
  const router = useRouter()
  const topInvestBtnRef = useRef(null)
  const topCtaIsVisible = useOnScreen(topInvestBtnRef)
  const { start_video: startVideo } = router.query
  const [modalYouTubeId, setModalYouTubeId] = useState<string>('')
  const [subscribedEmail, setSubscribedEmail] = useState<string | null>(null) // TODO: should this be persisted? DB? localStorage?
  const pageContent = content && contentUtil.mapContent(content)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({
        event: 'landing-page',
        campaign: campaign.name,
        status: campaign.regulationType,
      })
    }
  }, [campaign])

  useEffect(() => {
    if (typeof startVideo === 'string') {
      setModalYouTubeId(startVideo)
    }
  }, [startVideo])

  useEffect(() => {
    if (campaign) {
      fbPixel.init(
        campaign.facebookTrackingPixel ||
          (process.env.NEXT_PUBLIC_FB_PIXEL as string)
      )
      fbPixel.pageView()
      SegmentHandler.track(
        'Campaign Pageview',
        { campaign: campaign.name, project: campaign.slug },
        { suppressSegmentTrack: true }
      )
    }
  }, [campaign])

  return (
    <div className="font-whitney min-h-screen">
      {campaign.slug && (
        <CampaignAssetsProvider campaignAssets={campaignAssets?.fields}>
          <DetailsProvider
            campaign={campaign}
            campaignDetails={campaignDetails}
            content={pageContent?.content}
          >
            <VideoHero
              video={
                pageContent?.headerYouTubeId ||
                campaignDetails?.header?.youtube_id
              }
              campaign={campaign}
              subscribedEmail={subscribedEmail}
              setSubscribedEmail={setSubscribedEmail}
              setModalVideo={setModalYouTubeId}
              modalIsOpen={!!modalYouTubeId}
              anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
              aspectRatio={videoAspectRatio}
            />

            <div
              id="offering_content"
              className="w-full px-6 py-3 lg:pt-6 lg:text-center xl:hidden"
              style={{ maxWidth: 1264 }}
            >
              <CampaignHeading {...campaign} theme="dark" />

              <CampaignTags
                tags={campaignAssets?.fields?.tags}
                className="mt-2 -mx-6 px-6 hide-scrollbar overflow-auto whitespace-nowrap"
              />

              <CampaignDescription
                document={campaignAssets?.fields?.shortDescription}
                className="mt-3"
              />
            </div>

            <InvestCard
              investButtonWrapperRef={topInvestBtnRef}
              campaign={campaign}
              subscribedEmail={subscribedEmail}
              setSubscribedEmail={setSubscribedEmail}
              className="p-6 border-t border-b md:border border-lightGray md:mx-6 md:rounded xl:hidden"
              style={{
                background:
                  'transparent linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box',
              }}
              sectionName="body"
              anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
            />

            <DetailsTabs campaign={campaign} />

            <div
              className="h-full w-full flex flex-col-reverse lg:flex-row lg:mx-auto px-6 xl:px-0"
              style={{ maxWidth: 1264 }}
            >
              <div className="flex flex-col lg:w-2/3">
                <div className="mb-6">
                  <Details />
                </div>

                {campaign?.regulationType === 'CF' && (
                  <PublicComments
                    isOpen={
                      !!campaign.closeDate &&
                      new Date(campaign.closeDate) > new Date()
                    }
                    campaignName={campaign.name}
                    campaignSlug={campaign.slug}
                    className="mb-6"
                  />
                )}

                {siteAssets.cognitoFormsId && siteAssets.cognitoFormsKey && (
                  <div id="whitelabel-contact-form" className="mb-6 md:mb-8">
                    <script
                      src="https://www.cognitoforms.com/f/seamless.js"
                      data-key={siteAssets.cognitoFormsKey}
                      data-form={siteAssets.cognitoFormsId}
                    ></script>
                  </div>
                )}

                <CampaignShareBox
                  campaign={campaign}
                  className="lg:hidden w-full mb-6"
                />

                <MobileInvestButtonWrapper
                  campaign={campaign}
                  color="primary"
                  sectionName="body"
                  setSubscribedEmail={setSubscribedEmail}
                  subscribedEmail={subscribedEmail}
                  topCtaIsVisible={topCtaIsVisible}
                />
              </div>

              <div className="hidden lg:block lg:w-1/3 lg:pl-8">
                <div className="lg:sticky lg:top-24 mt-6">
                  <InvestCard
                    campaign={campaign}
                    subscribedEmail={subscribedEmail}
                    setSubscribedEmail={setSubscribedEmail}
                    className="p-6 border border-lightGray rounded"
                    style={{
                      background:
                        'transparent linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box',
                    }}
                    sectionName="sidebar"
                    anticipatedRegulation={
                      pageContent?.anticipatedRegulation?.fields
                    }
                    investButtonWrapperRef={null}
                  />
                </div>
              </div>
            </div>
          </DetailsProvider>
        </CampaignAssetsProvider>
      )}

      <VideoModal
        isOpen={!!modalYouTubeId}
        setIsOpen={(isOpen) => setModalYouTubeId(isOpen ? modalYouTubeId : '')}
        videoId={modalYouTubeId}
        campaignSlug={campaign.slug}
        aspectRatio={videoAspectRatio}
      />
    </div>
  )
}

export default CampaignPage

// Used by Next to Generate static pages for dynamic Routes
export const getStaticPaths: GetStaticPaths = async () => {
  const campaigns = await getAllCampaigns()
  const pathsData = campaigns
    ?.filter((c) => {
      return c.enabled
    })
    .map((c) => {
      return { params: { slug: [c.slug] } }
    })
  return {
    paths: pathsData,
    fallback: false,
  }
}

// Used by Next to pre-fetch data to build static pages
/* eslint-disable no-console */
export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context?.params?.slug?.[0]

  if (!slug || typeof slug !== 'string') {
    return {
      props: {},
    }
  }

  const { key: campaignDetailsKey, request: detailsRequest } =
    getCampaignDetails({ slug })

  const [campaign, details, content, siteAssets, campaignAssets] =
    await Promise.all(
      [
        getCampaign(slug),
        detailsRequest(),
        getContent({
          content_type: 'pageCampaign',
          'fields.slug': slug,
        }),
        getContent({
          content_type: 'siteAssets',
          'fields.contentfulName': getSiteAssetsID(
            process.env.NEXT_PUBLIC_SITE_DOMAIN
          ),
        }).then((res) =>
          resolveSiteAssets(res?.items?.[0]?.fields as ISiteAssetsFields)
        ),
        getContent({
          content_type: 'campaignAssets',
          'fields.slug': slug,
        }),
      ].map((p) => p.catch((err) => err))
    )

  // Log the error for debugging
  if (campaign instanceof Error)
    console.error('Error getting campaign', campaign)
  if (details instanceof Error)
    console.error('Error getting campaign details', details)
  if (content instanceof Error) console.error('Error getting content', content)
  if (siteAssets instanceof Error)
    console.error('Error getting site assets', siteAssets)
  if (campaignAssets instanceof Error)
    console.error('Error getting campaign assets', campaignAssets)

  const seo =
    content?.items?.[0]?.fields?.seo?.fields || details?.data?.meta || {}

  // We need either details or content to show this page
  if (!details?.data && !content?.items?.[0]) {
    return {
      notFound: true,
    }
  }

  const props = {
    campaign: campaign || null,
    campaignAssets: campaignAssets?.items?.[0] || null,
    campaignDetails: details?.data || null,
    content: content?.items?.[0] || null,
    fallback: {
      [`campaign/${slug}`]: campaign || null,
      [campaignDetailsKey]: details?.data || null,
    },
    seo,
    siteAssets,
    ...(slug === 'david-2' ? { videoAspectRatio: 2.3879 } : {}),
  }

  return {
    props,
  }
}
