import { useCallback } from 'react'
import { useRouter } from 'next/router'
import SegmentHandler from '@/services/analytics/SegmentHandler'

interface Props {
  campaignSlug: string
  docs?: {
    [name: string]: string
  }
  offerings?: {
    id: string
    name: string
    url: string
  }[]
}

// TODO: `docs` come from old CMS, `offerings` from Contentful. Remove docs after migration.
export default function Offering({ campaignSlug, docs, offerings }: Props) {
  const router = useRouter()

  const handleClick = useCallback(
    (name, url) => {
      SegmentHandler.track('On Tap', {
        project: campaignSlug,
        location: router.asPath,
        section_name: 'body',
        element_name: 'link',
        value: name,
        target_url: url,
        action_type: 'tap',
      })
    },
    [campaignSlug, router.asPath]
  )

  return (
    <div className="flex flex-wrap items-center">
      {offerings &&
        offerings.map(({ id, name, url }) => (
          <a
            key={id}
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-left text-primary text-lg font-semibold w-full md:w-1/3 px-4 my-2"
            onClick={() => handleClick(name, url)}
          >
            {name}
          </a>
        ))}
      {/* For backwards compatibility with old CMS. TODO: remove section when we've migrated to Contentful */}
      {!offerings &&
        docs &&
        Object.entries(docs)
          .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
          .map(([name, url], index) => (
            <a
              key={index}
              href={String(url)}
              target="_blank"
              rel="noreferrer"
              className="text-left text-primary text-lg font-semibold w-full md:w-1/3 px-4 my-2"
              onClick={() => handleClick(name, url)}
            >
              {name}
            </a>
          ))}
    </div>
  )
}
