import React from 'react'
import { ISectionOverviewFields } from 'src/types/generated/contentful'

import TextBlock from '@/components/common/TextBlock'
import Bios from '../Bios'
import Testimonials from '../Testimonials'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../../../constants/contentful'

interface Props {
  campaignSlug: string
  section: ISectionOverviewFields
}

export const Overview = ({ campaignSlug, section }: Props) => {
  const items = section.content.map((itemRaw, i) => {
    const item = contentUtil.mapContent(itemRaw)
    if (!item) return null

    return (
      <div
        key={item.id}
        className={i < section.content.length - 1 ? 'mb-4' : ''}
        style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray' }}
      >
        {item.contentType === 'sectionContentBlock' &&
          item.layout === CONTENT_BLOCK_LAYOUT.BIO && (
            <Bios items={item.items} />
          )}
        {item.contentType === 'sectionContentBlock' &&
          item.layout === CONTENT_BLOCK_LAYOUT.TESTIMONIAL && (
            <Testimonials items={item.items} />
          )}
        {item.contentType === 'textBlock' && (
          <TextBlock campaignSlug={campaignSlug} document={item.text} />
        )}
      </div>
    )
  })

  return <div className="h-full">{items}</div>
}

export default Overview
