import React, { useCallback, useState, useRef } from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { object, string, boolean } from 'yup'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { updateUserName, updateFinancialTies } from '@/services/api/endpoints'
import useAuth from '@/services/hooks/useAuth'
import { Comment } from '@/services/api/publicComments/publicComments'
import { resolveErrorMessage, ErrorObject } from '@/utils'
import { Button, FormError, FormInput } from '@/components/common'
import SegmentHandler from '@/services/analytics/SegmentHandler'

export interface Props {
  campaignSlug: string
  onSubmit: (userId: string, text: string) => Promise<Comment>
  isResponse?: boolean
  placeholder?: string
  className?: string
}

const nameForm: { name: 'firstName' | 'lastName'; placeholder: string }[] = [
  { name: 'firstName', placeholder: 'Legal First Name' },
  { name: 'lastName', placeholder: 'Legal Last Name' },
]

const validationSchema = object({
  answer: string().min(3, 'Too short').required('Please enter a reply'),
  firstName: string().min(2, 'Too short').required('Please enter a first name'),
  lastName: string().min(2, 'Too short').required('Please enter a last name'),
  financialTies: boolean(),
})

const CampaignCommentForm: React.FC<Props> = ({
  campaignSlug,
  onSubmit,
  isResponse = false,
  placeholder = 'Type to reply',
  className,
}) => {
  const { user } = useAuth()
  const [focused, setFocused] = useState(false)
  const formRef = useRef<FormikProps<FormikValues>>(null)
  const router = useRouter()

  const cleanup = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm()
      setFocused(false)
    }
  }, [])

  const resolvedOnSubmit = useCallback(
    async (formValues, formik) => {
      if (!user) return

      try {
        if (!user.first_name || !user.last_name) {
          await updateUserName(
            user.id,
            formValues.firstName,
            formValues.lastName
          )
        }

        if (
          !user.related_campaigns.includes(campaignSlug) &&
          formValues.financialTies
        ) {
          await updateFinancialTies(
            [...user.related_campaigns, campaignSlug],
            user.id
          )
        }

        if (
          user.related_campaigns.includes(campaignSlug) &&
          !formValues.financialTies
        ) {
          await updateFinancialTies(
            user.related_campaigns.filter(
              (slug: string) => slug !== campaignSlug
            ),
            user.id
          )
        }

        await onSubmit(user.id, formValues.answer)

        cleanup()
      } catch (e) {
        formik.setSubmitting(false)
        alert(resolveErrorMessage(e as ErrorObject))
      }
    },
    [user, campaignSlug, onSubmit, cleanup]
  )

  const onFocus = useCallback(() => {
    setFocused(true)

    SegmentHandler.track('User Message Started', {
      project: campaignSlug,
      location: router.asPath,
      action_type: 'keyboard',
      message_type: isResponse ? 'response' : 'comment',
    })
  }, [isResponse, router.asPath, campaignSlug])

  return (
    <div className={className}>
      <Formik
        initialValues={{
          answer: '',
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          financialTies:
            user?.related_campaigns.includes(campaignSlug) || false,
        }}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={resolvedOnSubmit}
        innerRef={formRef}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          submitCount,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="block relative">
            <div className="relative">
              {(!user?.first_name || !user?.last_name) && (
                <div
                  className={classNames({
                    'transition-all ease-in-out grid grid-cols-2 gap-4': true,
                    'opacity-0 pointer-events-none h-0': !focused,
                    'opacity-100 h-16': focused,
                  })}
                >
                  {nameForm.map(({ name, placeholder }) => (
                    <input
                      key={name}
                      type="text"
                      name={name}
                      className="block w-full h-12 p-3 focus:outline-none bg-gray-10 text-sm border border-oxide rounded"
                      placeholder={placeholder}
                      value={values[name]}
                      onChange={(e) => setFieldValue(name, e.target.value)}
                    />
                  ))}
                </div>
              )}

              <textarea
                id="answer"
                name="answer"
                className={classNames({
                  'block w-full p-3 rounded bg-gray-10 border focus:outline-none text-sm transition-[height] ease-in-out duration-500 resize-none':
                    true,
                  'h-12 border-transparent': !focused,
                  'h-48 pb-12 border-oxide': focused,
                })}
                placeholder={placeholder}
                value={values.answer}
                onChange={(e) => setFieldValue('answer', e.target.value)}
                onFocus={onFocus}
              />

              <div
                className={classNames({
                  'absolute bottom-2 right-2 transition-opacity': true,
                  'opacity-0 pointer-events-none': !focused,
                  'opacity-100': focused,
                })}
              >
                <div className="flex items-center justify-end">
                  <FormError
                    className="px-3 text-right leading-tight"
                    error={
                      focused && submitCount > 0
                        ? (errors.answer as string) ||
                          (errors.firstName as string) ||
                          (errors.lastName as string) ||
                          null
                        : null
                    }
                  />

                  <Button size="sm" type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>

                  <Button
                    size="sm"
                    color="gray"
                    type="button"
                    onClick={() => setFocused(false)}
                    className="ml-2"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            {focused && !isResponse && (
              <div className="flex justify-end">
                <FormInput
                  name="financialTies"
                  type="toggle"
                  text="I have financial ties with this campaign"
                  className="mt-4 text-right italic"
                />
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CampaignCommentForm
