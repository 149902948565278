import classNames from 'classnames'
import React, { CSSProperties, LegacyRef } from 'react'

import ProgressBar from './InvestComponents/ProgressBar'
import AmountRaised from './InvestComponents/AmountRaised'
import Backers from './InvestComponents/Backers'
import SellingFast from './InvestComponents/SellingFast'
import TimeRemaining from './InvestComponents/TimeRemaining'
import MinInvestment from './InvestComponents/MinInvestment'
import Buffer from './InvestComponents/Buffer'
import PriorRaise from './InvestComponents/PriorRaise/PriorRaise'
import InvestButton from './InvestComponents/InvestButton'
import CampaignDisclaimer from './InvestComponents/CampaignDisclaimer'
import useCampaign from '@/services/hooks/useCampaign'
import InvestmentDetails from './InvestComponents/InvestmentDetails'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'
import { ISectionAnticipatedRegulationTypeFields } from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaignV2/campaign'
import useABTest from '@/services/hooks/useABTest'

interface Props {
  anticipatedRegulation: ISectionAnticipatedRegulationTypeFields
  campaign: Campaign
  className?: string
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  sectionName: 'body' | 'sidebar' | 'hero'
  setSubscribedEmail: (email: string) => void
  showDetails?: boolean
  style?: CSSProperties
  subscribedEmail: string | null
  theme?: 'dark' | 'light'
}

const InvestCard: React.FC<Props> = ({
  anticipatedRegulation,
  campaign,
  className,
  investButtonWrapperRef,
  sectionName,
  setSubscribedEmail,
  showDetails,
  style = {},
  subscribedEmail,
  theme = 'dark',
}) => {
  const { campaign: resolvedCampaign = campaign } = useCampaign(campaign.slug)
  const { regulationType, currentlyFunding } = resolvedCampaign

  return (
    <div className={className} style={style}>
      {regulationType !== 'testing_the_waters' && (
        <ProgressBar
          campaign={resolvedCampaign}
          trackColor={theme === 'light' ? 'white' : 'black'}
        />
      )}

      <div
        className={classNames({
          'mt-2 flex justify-between': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      >
        <div className="w-full flex md:block">
          <div>
            <AmountRaised campaign={resolvedCampaign} className="lg:mt-1" />

            <Backers campaign={resolvedCampaign} className="mt-3" />
          </div>
        </div>
        {currentlyFunding && regulationType !== 'testing_the_waters' && (
          <TimeRemaining campaign={resolvedCampaign} className="lg:mt-1 " />
        )}
      </div>

      <Buffer
        campaign={resolvedCampaign}
        className={classNames({
          'my-3': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />

      <SellingFast
        campaign={resolvedCampaign}
        className={classNames({
          'my-2': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />

      <div ref={investButtonWrapperRef}>
        <InvestButton
          campaign={resolvedCampaign}
          subscribedEmail={subscribedEmail}
          setSubscribedEmail={setSubscribedEmail}
          style={{ maxWidth: 420 }}
          data-cy={
            currentlyFunding ? 'project-cta-button' : 'closed-offering-button'
          }
          className="block w-full mt-2 mx-auto"
          sectionName={sectionName}
        />
      </div>

      {currentlyFunding && regulationType !== 'testing_the_waters' && (
        <div
          className={classNames({
            'text-black': theme === 'dark',
            'text-white': theme === 'light',
          })}
        >
          <MinInvestment
            campaign={resolvedCampaign}
            className="mt-3 text-center font-light"
          />
        </div>
      )}

      {sectionName !== 'hero' && (
        <div className="mt-4 mb-2">
          <PriorRaise
            campaign={resolvedCampaign}
            className={classNames({
              'border-t last:border-b py-2': true,
              'text-black border-gray-8': theme === 'dark',
              'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
            })}
          />

          {showDetails && (
            <InvestmentDetails
              campaign={resolvedCampaign}
              className={classNames({
                'border-t last:border-b py-2': true,
                'text-black border-gray-8': theme === 'dark',
                'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
              })}
            />
          )}
        </div>
      )}

      <CampaignDisclaimer
        className={classNames({
          'mt-4 px-1 mx-auto text-[11px] leading-tight tracking-none': true,
          'text-gray': theme !== 'light',
          'text-white': theme === 'light',
        })}
        campaign={resolvedCampaign}
        anticipatedRegulation={anticipatedRegulation}
      />
    </div>
  )
}

const ABInvestCard = (props: Props) => {
  const assets = useCampaignAssets()
  const [decision] = useABTest('invest_card_details')
  const variationOn =
    typeof decision === 'object' && decision.variationKey === 'on'

  if (!assets.valuation) return <InvestCard {...props} />

  return <InvestCard {...props} showDetails={variationOn} />
}

export default ABInvestCard
