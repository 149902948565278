import React, { useMemo } from 'react'
import useApi from '@/services/hooks/useApiV2'
import { getLivestreamComments } from '@/services/api/endpoints'
import { Text } from '@/components/common'

interface Props {
  video: string
}

interface Comment {
  id?: string
  stream_id: string
  date: string
  text: string
  platform: 'youtube'
  author_name: string
}

const CommentSection = ({ video }: Props) => {
  const { data: comments = [], isError } = useApi(
    getLivestreamComments({ video })
  )

  // TODO: need to check if backend is setting duplicate comments
  const deduped = useMemo(() => {
    if (isError || !Array.isArray(comments)) return []

    return comments
      .map(({ author_name, date, ...rest }: Comment) => ({
        ...rest,
        id: `${author_name} ${date}`,
        author_name,
        date,
      }))
      .reduce((acc: Comment[], cur: Comment) => {
        if (!acc.find(({ id }) => cur.id === id)) {
          return [...acc, cur]
        }

        return acc
      }, [])
  }, [comments, isError])

  if (!comments.length || isError) return null

  return (
    <div className="background-gray h-52 overflow-scroll p-4 shadow w-full">
      {deduped.map((comment: Comment) => (
        <div
          key={comment.id}
          className="py-1"
          style={{ wordBreak: 'break-word' }}
        >
          <Text as="div" preset="body.sm" className="text-gray">
            {comment.author_name}
          </Text>
          <Text as="div" preset="body.md">
            {comment.text}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default CommentSection
