import React, { useCallback } from 'react'
import ABTester, { Experiment } from '@/components/ABTester'
import { report } from '@/utils'
import { Props } from './VideoHero'
import MobileFallbackVideoHero from './MobileFallbackVideoHero'

const ABVideoHero: React.FC<Props> = (props) => {
  const videos = props.video.split(',').map((v) => v.trim())

  const name = `video_hero_${props.campaign.slug}`

  const getVideo = useCallback(
    ({ variationKey, enabled }) => {
      if (!enabled) {
        return videos[0]
      }

      if (!variationKey) {
        report.warn(`No Optimizely Flag found with name ${name}`)
        return videos[0]
      }

      if (!videos[parseInt(variationKey, 10)]) {
        report.warn(`No video found for variation "${variationKey}" in ${name}`)
        return videos[0]
      }

      return videos[parseInt(variationKey, 10)]
    },
    [name, videos]
  )

  if (videos.length === 1) {
    return <MobileFallbackVideoHero {...props} />
  }

  return (
    <ABTester name={name} waitForClientReady={false}>
      {({ decision }: Experiment<string | null>) => (
        <MobileFallbackVideoHero {...props} video={getVideo(decision)} />
      )}
    </ABTester>
  )
}

export default ABVideoHero
