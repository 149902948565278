import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Markdown from '@/components/Markdown'

export const About = ({ image_url, subtitle, text, title }) => {
  return (
    <div className="">
      {title && (
        <div className="text-xl mx-3 md:mx-0 mb-8 md:mt-10">
          {/* Use ReactMarkdown instead of Markdown because we don't want additional formatting */}
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{title}</ReactMarkdown>
        </div>
      )}
      {image_url && (
        <img
          className="mb-8 -mx-6 w-screen max-w-none lg:mx-0 lg:w-full"
          src={image_url}
          alt="about this project image"
          loading="lazy"
        />
      )}
      {subtitle && <h3 className="m-3 md:m-0">{subtitle}</h3>}
      {text && (
        <div className="m-3 md:m-0">
          <Markdown source={text} />
        </div>
      )}
    </div>
  )
}

export default About
