import { Asset } from 'contentful'
import { ReactNode } from 'react'
import { IconType } from 'react-icons/lib'
import Image from '@/components/contentful/Image'

interface IconGroupProps {
  title: string
  description: string | ReactNode
  dataCy: string
  Icon?: IconType
  image?: Asset
}

const IconGroup = ({
  title,
  description,
  dataCy,
  Icon,
  image,
}: IconGroupProps) => (
  <div
    className="flex-1 flex flex-col items-between px-4 md:px-6 mb-6 md:mb-0"
    data-cy={dataCy}
  >
    <div className="flex flex-row items-center md:flex-col md:items-start">
      {/* prefer image over Icon if available */}
      {!image && Icon && (
        <div className="flex items-center h-20">
          <Icon />
        </div>
      )}
      {image && (
        <div className="flex items-center h-20">
          <Image image={image} width={78} />
        </div>
      )}
      <h3 className="text-xl ml-3 md:ml-0 leading-tight">{title}</h3>
    </div>
    {typeof description === 'string' ? (
      <p className="text-xs md:mt-2">{description}</p>
    ) : (
      <div className="text-xs md:mt-2">{description}</div>
    )}
  </div>
)

export default IconGroup
