import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'
import {
  ISectionContentBlockFields,
  ISectionOverviewFields,
  IVideoBlock,
} from 'src/types/generated/contentful'
import { contentUtil } from '@/utils'
import { Campaign as ICampaign } from '@/services/api/campaignV2/campaign'

import HowItWorks from '@/components/pages/CampaignPages/HowItWorks'
import Overview from '@/components/pages/CampaignPages/DetailComponents/Overview'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import Offering from '@/components/pages/CampaignPages/Offering'
import Livestream from '@/components/pages/CampaignPages/Livestream'
import Perks from '@/components/pages/CampaignPages/Perks'

interface Props {
  campaign: ICampaign
  content: contentUtil.MappedContent
}

const Dynamic = ({ campaign, content }: Props) => {
  switch (content.contentType) {
    case 'faqSection': {
      const faqs = {
        questions:
          content?.faqs?.length && content.faqs.map(contentUtil.mapContent),
      }
      return <FAQ faq={faqs} />
    }
    case 'sectionContentBlock':
      if (content.layout === CONTENT_BLOCK_LAYOUT.CARD) {
        return (
          <HowItWorks
            content={content as unknown as ISectionContentBlockFields}
            regulationType={campaign.regulationType}
          />
        )
      } else if (content.layout === CONTENT_BLOCK_LAYOUT.PERKS) {
        const perks = content?.items?.map(contentUtil.mapContent)
        return <Perks perks={perks} />
      }
      break
    case 'sectionLivestream': {
      const livestreams =
        content.livestreams.length &&
        content.livestreams
          // remove livestreams that don't have fields (i.e. in draft mode)
          .filter((ls: IVideoBlock) => ls.fields)
          .map((ls: IVideoBlock) => ({
            id: ls.fields.id,
            name: ls.fields.title,
          }))
      return (
        <Livestream campaignSlug={campaign.slug} livestreams={livestreams} />
      )
    }
    case 'sectionOffering': {
      const offerings =
        content?.links?.length && content.links.map(contentUtil.mapContent)
      return <Offering campaignSlug={campaign.slug} offerings={offerings} />
    }
    case 'sectionOverview':
      return (
        <Overview
          campaignSlug={campaign.slug}
          section={content as unknown as ISectionOverviewFields}
        />
      )
    default:
      break
  }
  return null
}

export default Dynamic
