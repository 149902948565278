import React from 'react'
import classNames from 'classnames'
import { Text } from '@/components/common'
import { Campaign } from '@/services/api/campaignV2/campaign'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'
import { whiteLabelUtil } from '@/utils'

interface Props extends Campaign {
  theme?: 'light' | 'dark'
  className?: string
}

const CampaignHeading: React.FC<Props> = ({
  regulationType,
  currentlyFunding,
  name,
  offeringString,
  theme = 'light',
  className = '',
}) => {
  const campaignAssets = useCampaignAssets()
  let label
  if (regulationType === 'testing_the_waters') {
    label = 'Express Interest in'
  } else {
    label = currentlyFunding ? 'Now Funding' : 'Not Currently Funding'
  }

  return (
    <div
      className={classNames(
        {
          'text-white': theme === 'light',
          'text-black': theme !== 'light',
        },
        className
      )}
    >
      <Text
        as="p"
        preset="body.md"
        className={classNames({
          'text-darkGray': theme !== 'light',
        })}
      >
        <Text
          as="span"
          preset="custom"
          className={classNames('mr-1 tracking-wide semi-bold uppercase', {
            'text-darkGray': theme !== 'light',
          })}
          data-cy="funding-status-heading"
        >
          {label} {!whiteLabelUtil.isWhiteLabel() && '|'}
        </Text>
        {!whiteLabelUtil.isWhiteLabel() && offeringString}
      </Text>
      <Text
        as="h2"
        preset="heading.lg md:heading.xl"
        data-cy="project-name-header"
      >
        {name}
      </Text>
      {campaignAssets.subtitle && (
        <Text as="p" preset="body.lg">
          {campaignAssets.subtitle}
        </Text>
      )}
    </div>
  )
}

export default CampaignHeading
