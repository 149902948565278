import React from 'react'
import { Document } from '@contentful/rich-text-types'
import { ExpandableTextBlock } from '@/components/common'
import ABTester, { Experiment } from '@/components/ABTester'

interface Props {
  document?: Document
  className?: string
}

export const CampaignDescription: React.FC<Props> = ({
  document,
  className,
}) => {
  if (!document) return null

  return (
    <ExpandableTextBlock
      lines={3}
      className={className}
      textClassName="text-sm font-light"
      document={document}
    />
  )
}

type ExperimentType = 'on' | 'off' | 'tags_only' | 'description_only'

const ABCampaignDescription: React.FC<Props> = (props) => {
  // Don't expose experiment if no description
  if (!props.document) return <CampaignDescription {...props} />

  return (
    <ABTester name="campaign_tags">
      {({ decision }: Experiment<ExperimentType>) => {
        const document =
          decision.variationKey === 'on' ||
          decision.variationKey === 'description_only'
            ? props.document
            : undefined

        return <CampaignDescription {...props} document={document} />
      }}
    </ABTester>
  )
}

export default ABCampaignDescription
