import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import InvestButton from './InvestButton'
import useOnScreen from '@/services/hooks/useOnScreen'
import { Campaign } from '@/services/api/campaignV2/campaign'
import ABTester, { ABTestProps } from '@/components/ABTester'
import MinInvestment from './MinInvestment'

interface Props {
  campaign: Campaign
  showMinInvestment?: boolean
  topCtaIsVisible?: boolean
  [key: string]: any
}

export const MobileInvestButtonWrapper: React.FC<Props> = ({
  campaign,
  showMinInvestment,
  topCtaIsVisible,
  ...investButtonProps
}) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (!document) return

    document.documentElement.style.setProperty(
      '--zendesk-bottom-position',
      !isVisible || topCtaIsVisible ? '0.75rem' : '5.5rem'
    )
  }, [isVisible, topCtaIsVisible])

  return (
    <div
      className={classNames(
        'bg-white lg:hidden sticky bottom-0 left-0 px-6 py-4 right-0 text-center z-50 -mx-6',
        {
          hidden: topCtaIsVisible,
        }
      )}
      style={{ boxShadow: '0px -8px 8px rgba(106, 115, 129, 0.12)' }}
      ref={ref}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <InvestButton
        {...investButtonProps}
        campaign={campaign}
        className="max-w-[420px] text-sm w-full"
        rounded
        size={'md'}
      />

      {showMinInvestment && (
        <MinInvestment
          campaign={campaign}
          preset="custom"
          className="uppercase text-xs font-semibold mt-2 text-gray tracking-wide"
        />
      )}
    </div>
  )
}

const ABMobileInvestButtonWrapper = ({
  campaign,
  ...props
}: {
  campaign: Campaign
  [key: string]: any
}) => {
  if (
    !campaign.currentlyFunding ||
    campaign.regulationType === 'testing_the_waters'
  ) {
    return <MobileInvestButtonWrapper {...props} campaign={campaign} />
  }

  return (
    <ABTester name="min_investment">
      {({ decision }: ABTestProps) => {
        return (
          <MobileInvestButtonWrapper
            campaign={campaign}
            {...props}
            showMinInvestment={decision.variationKey === 'on'}
          />
        )
      }}
    </ABTester>
  )
}

export default ABMobileInvestButtonWrapper
