import classNames from 'classnames'
import { richText } from '@/utils'
import { Document } from '@contentful/rich-text-types'
import { Text } from '@/components/common'

interface Props {
  perks: {
    id: string
    subtitle?: string
    title: string
    text?: string | Document
  }[]
}

export default function Perks({ perks }: Props) {
  if (!perks?.length) {
    return null
  }

  return (
    <>
      {perks.map(({ id, subtitle, title, text }, idx) => (
        <div
          className={classNames(
            'border border-gray-8 p-6 relative rounded-sm overflow-hidden w-full flex flex-col',
            {
              'mb-4': idx < perks.length - 1,
            }
          )}
          key={id}
        >
          <Text
            as="p"
            preset="custom"
            className="text-primary font-semibold text-lg"
          >
            {title}
          </Text>
          {subtitle && (
            <Text as="p" preset="body.lg" className="mt-4 mb-2 font-semibold">
              {subtitle}
            </Text>
          )}
          {text && (
            <Text
              as={typeof text === 'string' ? 'p' : 'div'}
              className="text-gray"
              preset="body.md"
            >
              {typeof text === 'string' ? text : richText(text)}
            </Text>
          )}
        </div>
      ))}
    </>
  )
}
