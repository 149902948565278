import { useState } from 'react'
import Markdown from '../../Markdown'
import { CardModal, VideoModal, FilledButton } from '@/components/common'

export default function Script({ details, campaignSlug }) {
  const [openDisclaimer, setopenDisclaimer] = useState(false)
  const [listening, setListening] = useState(false)
  const [openListeningModal, setOpenListeningModal] = useState(false)

  const handleContinue = () => {
    setopenDisclaimer(false)
    if (!listening) {
      window.open(details.link, '_blank')
    } else {
      // This timeout is to avoid conflicts with Tailwind headless UI
      // modal components. Without the timeout, it's throwing a warning about
      // "no focusable elements".
      setTimeout(() => {
        setOpenListeningModal(true)
      }, 300)
    }
  }

  const handleClick = (listen) => {
    setopenDisclaimer(true)
    setListening(listen)
  }

  return (
    <div className="mb-20">
      <div className="text-xl mx-3 md:mx-0 mb-8">See The Completed Script</div>
      <div className="mx-3 md:mx-0 mb-8">
        <Markdown source={details.message} />
      </div>
      <div className="items-center justify-items-center flex flex-col">
        <FilledButton
          color="orange"
          onClick={() => handleClick(false)}
          className="mt-4"
        >
          Read the full script
        </FilledButton>
        <FilledButton
          color="orange"
          onClick={() => handleClick(true)}
          className="mt-4"
        >
          Hear the full script
        </FilledButton>
      </div>

      <CardModal
        isOpen={openDisclaimer}
        setIsOpen={() => setopenDisclaimer(false)}
      >
        <div className="w-[570px] max-w-full px-8 py-6 sm:px-16 sm:py-8">
          <Markdown source={details.disclaimer} />
          <FilledButton
            color="orange"
            onClick={handleContinue}
            className="mt-5"
          >
            {listening
              ? `Got it. Let's listen to the script!`
              : `Got it. Let's see the script!`}
          </FilledButton>
        </div>
      </CardModal>

      <VideoModal
        videoId={details.audio_vid}
        campaignSlug={campaignSlug}
        isOpen={openListeningModal}
        setIsOpen={() => setOpenListeningModal(false)}
      />
    </div>
  )
}
