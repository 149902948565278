import { Campaign } from '@/services/api/campaignV2/campaign'
import { Text } from '@/components/common'

interface Props {
  campaign: Campaign
  className?: string
}

const PriorRaise = ({ campaign, className }: Props) => {
  const priorRaises = campaign.priorRaiseTotal

  if (!priorRaises) return null

  return (
    <div className={className}>
      <div className="flex justify-between items-center">
        <Text
          as="div"
          preset="heading.eyebrow"
          className="uppercase leading-none flex-1"
        >
          Previous Rounds:
        </Text>

        <div className="ml-8 flex-grow-0">
          <Text as="div" preset="body.md">
            {priorRaises.amount}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Raised
          </Text>
        </div>

        <div className="ml-8 flex-[0_3.75rem]">
          <Text as="div" preset="body.md">
            {priorRaises.backers.toLocaleString()}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Backers
          </Text>
        </div>
      </div>
    </div>
  )
}

export default PriorRaise
