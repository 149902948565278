import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { Comment } from '@/services/api/publicComments/publicComments'
import { Text, Button } from '@/components/common'
import CampaignComment from '@/components/CampaignComment'
import CampaignCommentForm from '@/components/CampaignComment/CampaignCommentForm'
import useComments from '@/services/hooks/useComments'
import useAuth from '@/services/hooks/useAuth'

export interface Props {
  campaignName: string
  campaignSlug: string
  isOpen: boolean
  className?: string
}

const PublicComments: React.FC<Props> = ({
  campaignName,
  campaignSlug,
  isOpen,
  className,
}) => {
  const { user, openModal } = useAuth()
  const [page, setPage] = useState(1)

  const { comments, isLoading, postComment } = useComments({
    campaign: campaignSlug,
    page,
    isPaginated: false,
  })

  const handleSubmit = useCallback(
    (userId: string, text: string) =>
      postComment({ userId, text, campaign: campaignSlug }),
    [campaignSlug, postComment]
  )

  return (
    <div className={className}>
      {isOpen && (
        <div className="bg-gray-9 rounded p-4 sm:p-6 mb-4 sm:mb-6 last:mb-0">
          <Text
            as="h2"
            preset="heading.md sm:heading.lg"
            className="mb-3 sm:mb-4 text-center sm:text-left"
          >
            Ask The Creators a Question
          </Text>

          {user ? (
            <CampaignCommentForm
              campaignSlug={campaignSlug}
              onSubmit={handleSubmit}
              placeholder="Type your question"
            />
          ) : (
            <div className="flex justify-center">
              <Button size="sm" onClick={() => openModal()}>
                Sign Up or Log In to Comment
              </Button>
            </div>
          )}
        </div>
      )}

      {comments.results.length > 0 && (
        <ul
          className={classNames({
            'transition-opacity': true,
            'opacity-50': isLoading,
          })}
        >
          {comments.results.map((comment: Comment) => (
            <li key={comment.id} className="mt-4 sm:mt-6 first:mt-0">
              <CampaignComment
                campaignName={campaignName}
                {...comment}
                className="bg-gray-9 p-4 sm:p-6 rounded"
              />
            </li>
          ))}
        </ul>
      )}

      {comments.next && (
        <div className="flex justify-center my-6">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setPage((cur) => cur + 1)}
            disabled={isLoading}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  )
}

export default PublicComments
