import React, { useState, createContext, useMemo, useContext } from 'react'
import OverviewOld from './DetailComponents/OverviewOld'
import Dynamic from '../../contentful/Dynamic'
import FAQ from './FAQ'
import Perks from './Perks'
import Offering from './Offering'
import Livestream from './Livestream'
import HowItWorks from './HowItWorks'
import Script from './Script'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'

const DetailsContext = createContext()

const TAB_IDS = {
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.CARD}`]: 'section-how-it-works',
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.PERKS}`]: 'section-perks',
  faqSection: 'section-faq',
  sectionLivestream: 'section-livestream',
  sectionOffering: 'section-offering',
  sectionOverview: 'section-overview',
  sectionScript: 'section-script',
}

export const useDetailsContext = () => useContext(DetailsContext)

export const DetailsProvider = ({
  children,
  campaign = {},
  campaignDetails = {},
  content = [],
}) => {
  const [tab, setTab] = useState(-1)

  const tabs = useMemo(() => {
    let allTabs = []

    if (content?.length) {
      allTabs = content.map((c) => {
        const componentContent = contentUtil.mapContent(c)

        let isVisible = !!componentContent
        if (componentContent.contentType === 'faqSection') {
          isVisible = isVisible && componentContent?.faqs?.length
        } else if (componentContent.contentType === 'sectionLivestream') {
          isVisible = isVisible && componentContent?.livestreams?.length
        }

        return {
          title: componentContent?.title,
          id: TAB_IDS[
            componentContent.contentType + (componentContent?.layout || '')
          ],
          visible: isVisible,
          Component() {
            return (
              <Dynamic
                key={componentContent.id}
                content={componentContent}
                campaign={campaign}
              />
            )
          },
        }
      })
    } else {
      const perks = campaign.perks.map((perk) => ({
        id: perk.name + perk.descriptionShort,
        subtitle: perk.descriptionShort,
        title: perk.name,
        text: perk.descriptionLong,
      }))

      allTabs = [
        {
          title: 'How it Works',
          id: 'section-how-it-works',
          visible: campaignDetails?.how_it_works,
          Component() {
            return (
              <HowItWorks
                howItWorks={campaignDetails?.how_it_works}
                regulationType={campaign.regulationType}
              />
            )
          },
        },
        {
          title: 'Overview',
          id: 'section-overview',
          visible: true,
          Component() {
            return (
              <OverviewOld
                about={campaignDetails?.about}
                campaignSlug={campaign.slug}
              />
            )
          },
        },
        {
          title: 'FAQ',
          id: 'section-faq',
          visible: campaignDetails?.faqs?.questions?.length,
          Component() {
            return <FAQ faq={campaignDetails?.faqs} />
          },
        },
        {
          title: 'Perks',
          id: 'section-perks',
          visible: perks?.length,
          Component() {
            return <Perks perks={perks} />
          },
        },
        {
          title: 'Offering',
          id: 'section-offering',
          visible: true,
          Component() {
            return (
              <Offering
                campaignSlug={campaign.slug}
                docs={campaignDetails?.offering_documents}
              />
            )
          },
        },
        {
          title: 'Script',
          id: 'section-script',
          visible:
            campaignDetails?.script_details?.link &&
            campaignDetails?.script_details?.audio_vid,
          Component() {
            return (
              <Script
                details={campaignDetails?.script_details}
                campaignSlug={campaign.slug}
              />
            )
          },
        },
        {
          title: 'Livestream',
          id: 'section-livestream',
          visible: campaignDetails?.livestream_details?.length,
          Component() {
            return (
              <Livestream
                livestreams={campaignDetails?.livestream_details}
                campaignSlug={campaign.slug}
              />
            )
          },
        },
      ]
    }

    if (campaign.regulationType === 'testing_the_waters') {
      return allTabs.filter(
        ({ id }) =>
          id.includes('section-overview') || id.includes('section-script')
      )
    }

    return allTabs
  }, [campaign, campaignDetails, content])

  return (
    <DetailsContext.Provider
      value={{
        tabs: tabs.filter(({ visible }) => visible),
        selectedTab: tab,
        setTab,
      }}
    >
      {children}
    </DetailsContext.Provider>
  )
}
