import { report } from '@/utils'
/**
 * React Facebook Pixel Module
 *
 * This is a pared-down, modified copy of @package react-facebook-pixel
 * It appears that it is no longer maintained and we need the ability to make adjustments.
 */

//
let initialized = false
let debug = false
const STANDARD_EVENTS = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
]

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const isStandardEvent = (event: string) => STANDARD_EVENTS.includes(event)

const warn = (...args: any[]) => {
  if (!debug) {
    return
  }
  // eslint-disable-next-line no-console
  report.warn(['[fbPixel]'].concat(args).toString())
}

/**
 * Log
 * @param  {...any} args
 */
const log = (...args: any[]) => {
  if (!debug) {
    return
  }
  report.log(['[fbPixel]'].concat(args).toString())
}

const verifyInit = () => {
  if (!initialized) {
    warn(
      'Pixel not initialized before using call ReactPixel.init with required params'
    )
  }
  return initialized
}

//
const defaultOptions = {
  autoConfig: true,
  debug: false,
}

interface PixelFunc {
  (): void
  push: () => void
  loaded: boolean
  version: string
  queue: any[]
  callMethod?: any
}

interface Options {
  autoConfig?: boolean
  debug?: boolean
}

//
export default {
  init(
    pixelId: string,
    advancedMatching = {},
    options: Options = defaultOptions
  ) {
    initialized = typeof window !== 'undefined' && !!window.fbq
    ;(function (f, b, e, v) {
      if (f.fbq) return
      const n = <PixelFunc>(f.fbq = function (...args: any[]) {
        n.callMethod ? n.callMethod(args) : n.queue.push(args)
      })
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      const t = b.createElement(e) as HTMLScriptElement
      t.async = !0
      t.src = v
      const s = b.getElementsByTagName(e)[0]
      if (s?.parentNode) s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )

    if (!pixelId) {
      warn('Please insert pixel id for initializing')
    } else {
      if (options.autoConfig === false) {
        window.fbq('set', 'autoConfig', false, pixelId)
      }

      window.fbq('init', pixelId, advancedMatching)

      initialized = true
      debug = !!options.debug
    }
  },

  pageView() {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'PageView')

    if (debug) {
      log("called fbq('track', 'PageView');")
    }
  },

  track(title: string, data: { [key: string]: any }, eventID: string) {
    if (!verifyInit()) {
      return
    }

    if (isStandardEvent(title)) {
      window.fbq('track', title, data, { eventID })
    } else {
      window.fbq('trackCustom', title, data, { eventID })
    }

    if (debug) {
      log(`called fbq('track', '${title}');`)

      if (data) {
        log('with data', data)
      }
      if (eventID) {
        log('with event ID', eventID)
      }
    }
  },
}
