import {
  HIWInvest,
  HIWCreate,
  HIWReturn,
  HandPointUp,
  HandHoldingUsd,
  NotificationsActive,
} from '@/components/svg'
import { useMemo } from 'react'

import colors from '../../../../config/colors'
import { ISectionContentBlockFields } from '../../../../types/generated/contentful'
import { contentUtil, richText } from '@/utils'
import IconGroup from './IconGroup'

const nonCFIconStyle = {
  fontSize: 35,
  fontWeight: '900',
  color: colors.secondary,
}

interface Props {
  content?: ISectionContentBlockFields
  howItWorks?: {
    backing: string
    creation: string
    returns: string
  }
  regulationType?: string
}

const cyProps = ['backing', 'creation', 'returns']

const HowItWorks = ({ content, howItWorks, regulationType }: Props) => {
  const isFunding = regulationType !== 'testing_the_waters'
  const items = useMemo(() => {
    if (!content?.items.length) return undefined

    return content.items.map(contentUtil.mapContent)
  }, [content])

  return (
    <div
      className="rounded"
      style={{ borderColor: 'lightgray', borderWidth: 1 }}
    >
      <div className="md:flex px-2 py-6" data-cy="TESTING">
        {/* Use content from Contentful if available, otherwise fallback to old CMS */}
        {items?.length ? (
          items.map((item, i) => (
            <IconGroup
              key={item?.id}
              title={item?.title}
              description={richText(item?.text)}
              dataCy={`how-it-works-${cyProps[i]}`}
              image={item?.image}
            />
          ))
        ) : (
          <>
            <IconGroup
              title={isFunding ? 'Invest' : 'Express Interest'}
              description={howItWorks?.backing}
              dataCy={`how-it-works-${cyProps[0]}`}
              Icon={
                isFunding
                  ? () => <HIWInvest width="78" height="71" />
                  : () => <HandPointUp style={nonCFIconStyle} />
              }
            />

            <IconGroup
              title={isFunding ? 'Creation' : 'Be the first to know'}
              description={howItWorks?.creation}
              dataCy={`how-it-works-${cyProps[1]}`}
              Icon={
                isFunding
                  ? () => <HIWCreate width="74" height="58" />
                  : () => <NotificationsActive style={nonCFIconStyle} />
              }
            />

            <IconGroup
              title={isFunding ? 'Returns' : 'Invest'}
              description={howItWorks?.returns}
              dataCy={`how-it-works-${cyProps[2]}`}
              Icon={
                isFunding
                  ? () => <HIWReturn width="78" height="69" />
                  : () => <HandHoldingUsd style={nonCFIconStyle} />
              }
            />
          </>
        )}
      </div>
    </div>
  )
}

export default HowItWorks
