import React from 'react'
import formatDate from 'date-fns/format'
import { Text, Popover } from '@/components/common'
import { Exclamation } from '@/components/svg'

export interface Props {
  name: string
  text: string
  created: string
  approved: boolean
  className?: string
}

const CampaignCommentBody: React.FC<Props> = ({
  name,
  text,
  created,
  approved,
  className,
}) => {
  return (
    <div className={className}>
      <header className="flex items-center">
        <Text as="h4" preset="heading.sm">
          {name}
        </Text>

        <Text as="span" preset="body.md" className="ml-2 text-gray">
          {formatDate(new Date(created), 'MMM dd, yyyy')}
        </Text>

        {!approved && (
          <Popover
            content={
              <div className="p-4">
                <Text as="h3" preset="heading.sm" className="text-white mb-1">
                  Pending Approval
                </Text>
                <Text as="p" preset="body.md" className="text-white">
                  {`This comment won't be visible to others until it
                is approved by our content moderation team.`}
                </Text>
              </div>
            }
          >
            <Exclamation className="text-gray-1 relative h-5 w-5 ml-2 -mt-[2px]" />
          </Popover>
        )}
      </header>

      <Text as="p" preset="body.md" className="mt-2 break-words">
        {text}
      </Text>
    </div>
  )
}

export default CampaignCommentBody
