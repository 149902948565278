import { Text } from '@/components/common'
import { Campaign } from '@/services/api/campaignV2/campaign'
import { formatMoney } from '@/utils'

interface Props {
  campaign: Campaign
  className?: string
  preset?: string
}

const MinInvestment: React.FC<Props> = ({
  campaign,
  className,
  preset = 'body.lg',
}) => {
  return (
    <Text as="p" preset={preset} className={className}>
      {formatMoney(campaign.minInvestment, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}{' '}
      Minimum Investment
    </Text>
  )
}

export default MinInvestment
