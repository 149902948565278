import { formatMoney } from '@/utils'

const AmountRaised = ({ campaign, className }) => {
  return (
    <div className={className}>
      <div className="flex flex-col">
        <div className="leading-1 lg:text-2xl text-xl" data-cy="capital-amount">
          {formatMoney(campaign.amountRaised, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
        <div className="text-sm">{campaign.progressString}</div>
      </div>
    </div>
  )
}

export default AmountRaised
